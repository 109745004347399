<template>
  <div class="flex items-center mt-2">
    <div class="flex items-center w-56 select-none text-sm leading-tight">
      {{ filter.label }}
    </div>
    <el-input
      v-model="textData"
      clearable
      autocorrect="off"
      autocapitalize="off"
      autocomplete="nope"
      spellcheck="false"
      :disabled="disabled"
      :placeholder="filter.placeholder"
      :maxlength="inputLimit"
      @clear="handleFilterInput"
      @keydown="preventNonNumeric"
      @update:modelValue="handleFilterInput"
    />
  </div>
</template>

<script>
import typeOf from 'just-typeof'
export default {
  props: {
    filter: {
      type: Object,
      required: true
    },
    tags: {
      type: Object,
      required: true
    },
    modelValue: {
      type: [String, Number],
      required: false,
      default: ''
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      textData: this.modelValue || null
    }
  },
  computed: {
    inputLimit() {
      return this.filter.maxLength || null
    },
    disabled() {
      const disable = this.filter && this.filter.disable
      if (disable && typeOf(disable) === 'function') {
        const isDisabled = disable(this.tags)
        if (isDisabled) {
          if (this.textData) {
            this.setToNull()
          }
          return isDisabled
        }
      }
      return false
    }
  },
  watch: {
    modelValue(newVal) {
      this.textData = this.formatAsNumeric(newVal)
    },
    textData(newVal) {
      if (
        this.inputLimit !== null &&
        newVal &&
        newVal.length > this.inputLimit
      ) {
        this.textData = newVal.slice(0, this.inputLimit)
      }
    }
  },
  methods: {
    handleFilterInput(v) {
      try {
        let value = this.formatAsNumeric(this.textData)

        if (this.inputLimit !== null && value.length > this.inputLimit) {
          value = value.slice(0, this.inputLimit)
        }
        this.$emit('update:modelValue', value)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error in card-input:', error)
      }
    },
    setToNull() {
      this.$emit('update:modelValue', null)
      this.textData = null
    },
    formatAsNumeric(value) {
      return value ? value.replace(/\D/g, '') : ''
    },
    preventNonNumeric(event) {
      // Allow: backspace, delete, tab, escape, and enter
      if (
        [46, 8, 9, 27, 13].indexOf(event.keyCode) !== -1 ||
        // Allow: Ctrl+A, Command+A
        (event.keyCode === 65 &&
          (event.ctrlKey === true || event.metaKey === true)) ||
        // Allow: home, end, left, right, down, up
        (event.keyCode >= 35 && event.keyCode <= 40)
      ) {
        // let it happen, don't do anything
        return
      }
      // Ensure that it is a number and stop the keypress
      if (
        (event.shiftKey || event.keyCode < 48 || event.keyCode > 57) &&
        (event.keyCode < 96 || event.keyCode > 105)
      ) {
        event.preventDefault()
      }
    }
  }
}
</script>
